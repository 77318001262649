import { SystemEvent, SystemEventLevel } from '@shared-ui/logger-context';

export const CDN_URL = 'https://c.travel-assets.com/advertiser-portal-pwa/';
export const CDN_BFLDR_URL = 'https://cdn.bfldr.com/QUXOL71E/at/m9f6h9bm8x8gm327j7ngq3r/';

export const LOG_EVENTS = {
  INFO_EVENT: new SystemEvent(SystemEventLevel.INFO, 'INFO_EVENT'),
  WARNING_EVENT: new SystemEvent(SystemEventLevel.WARN, 'WARNING_EVENT'),
  ERROR_EVENT: new SystemEvent(SystemEventLevel.ERROR, 'ERROR_EVENT'),
  CRITICAL_EVENT: new SystemEvent(SystemEventLevel.CRITICAL, 'CRITICAL_EVENT'),
};

export const TRACE_ID = 'TRACE-ID';

export const ERROR = 'ERROR';

export const JSON_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const TERMS_CONDITIONS_PROFILE_EXTENSION_PRODUCT = 'sponsored-content-campaign-management';
export const DIRECT_ADVERTISER = 'Direct advertiser';

export const ONBOARDING_COACHING_CARDS = new Map([
  [
    'partnerAccount',
    {
      headline: 'Partner account',
      text: 'A partner account contains your business and billing details, and helps you manage properties and Users.',
    },
  ],
  [
    'paymentProfile',
    {
      headline: 'Payment profile',
      text: 'This payment and billing profile will be used for all your invoices and transactions. Provide the information of the business or individual who will pay for your ads. This can be different than the information associated with your property in Partner Central.',
    },
  ],
  [
    'paymentMethod',
    {
      headline: 'Payment method',
      text: 'At this time, we can only accept payment through wire transfer or check. We’re working to add more payment methods soon.',
    },
  ],
  [
    'paymentCredit',
    {
      headline: 'Credit limit',
      text: "If paying by wire transfer or check, you'll be invoiced monthly (net 30 days) when you have ads running. By default you will be subject to a credit limit of $5,000 USD (or equivalent in your selected currency). If the limit is reached, campaigns may be paused. You can request an increase by contacting your Expedia Group sales representative.",
    },
  ],
  [
    'paymentCurrency',
    {
      headline: 'Currency',
      text: 'This is the currency you will be using for your campaign budget, and the currency you will be billed in. You will not be able to change currency once your campaign is live.',
    },
  ],
]);
